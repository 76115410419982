import image_69 from "../../assets/images/image69.png";
import hand from '../../assets/images/hand.png';
import tree from '../../assets/images/tree.png';

class BannerSectionNoButton extends HTMLElement {
    connectedCallback() {
        const title = this.getAttribute('title') || 'The easy-donate ORG';
        const description = this.getAttribute('description') || 'The easy-donate ORG';
        const btnText = this.getAttribute('btn-text');

        console.log(btnText);
        
        this.innerHTML = `
        <div class="banner-section-no-button">
            <div class="container">
                <div class="overlap-group4">
                    <div class="frame-96">
                        <div class="frame-95">
                            <p class="simple-affordable-a circularstd-bold-prussian-blue-45px">
                            ${title}
                            </p>
                            <p class="institutions-can-sta material-themebodylarge">
                            ${description}
                            </p>
                            ${btnText 
                                ? `
                                    <div class="frame-55">
                                        <a href="https://www.easy-donate.com/#/recipients" target="_parent">
                                            <div class="btn-5">
                                                <div class="sign-up valign-text-middle circularstd-bold-white-16px">${btnText}</div>
                                            </div>
                                        </a>
                                    </div>
                                `
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="hand">
                <img src="${tree}" alt="">
            </div>
        </div>
        `;
    }
}

class OurHistory extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <div class="about-us-components">
            <div class="frame-98">
                <div class="frame-54">
                    <div class="sub-tittle circularstd-bold-prussian-blue-36px">
                        Our history
                    </div>
                    <p class="the-easy-donate-star gilroy-medium-oxford-blue-18px">
                        The ccpathways.org started during on Fall 2023
                    </p>
                </div>
                <div class="frame-152">
                    <p class="easy-donateorg-was gilroy-medium-oxford-blue-18px">
                        <span class="gilroy-medium-oxford-blue-18px">
                            The ccpathways.org was created as a non-profit to further the 
                            mission of creating opportunities and providing access to
                            people and institutions across the world and create a level
                            playing field for everyone. Our initial focus is education
                            and supporting non-profits.
                            <br>
                            <br>
                            Our 
                            <b class="gilroy-bold-oxford-blue-18px">
                                Community College Apprenticeship & Internship
                            </b>
                            Program is focused on providing community college students
                            in the United States on-the-job apprenticeship and internship placement
                            in the software technology domain to help them build their
                            competency and find meaningful employment.
                            <br>
                            <br>
                            Our 
                            <b class="gilroy-bold-oxford-blue-18px">
                                Foreign Non-profit Support program
                            </b>
                            enables foreign charitable institutions who are validated to
                            receive foreign donations to leverage our 501c3 non-profit
                            status to raise funds from donors in the United States, and,
                            gain access to our digital marketing expertise to raise
                            awareness of their mission and activities.
                        </span>
                    </p>
                </div>
            </div>
        </div>
        `;
  }
}

class Mission extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <div class="about-us-components">
            <div class="frame-98">
                <div class="frame-54">
                    <div class="sub-tittle circularstd-bold-prussian-blue-36px">
                        Mission
                    </div>
                    <p class="the-easy-donate-star gilroy-medium-oxford-blue-18px">
                        The ccpathways.org started development during on Fall 2020
                    </p>
                </div>
                <div class="frame-152">
                    <img class="image-69-mob" id="image-69-mob-mission" src="${image_69}" alt="image 69" />
                    <p class="easy-donateorg-was gilroy-medium-oxford-blue-18px">
                        <span class="gilroy-medium-oxford-blue-18px">
                            The mission of ccpathways.org is to empower community college students 
                            with hands-on experience and industry-specific skills through structured 
                            apprenticeship programs that will prepare them for successful careers 
                            and bridging the skills gap between education and employment.
                        </span>
                    </p>
                </div>
            </div>
        </div>
        `;
  }
}

customElements.define("main-mission", Mission);
customElements.define("our-history", OurHistory);
customElements.define("banner-section-no-button", BannerSectionNoButton);
