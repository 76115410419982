import chevron_down from "../../assets/images/chevron-down@2x.svg";
import chevron_up from "../../assets/images/chevron-up.svg";
import line from "../../assets/images/line-4@1x.svg";
import hand from "../../assets/images/hand.png";
import tree from "../../assets/images/tree.png";
import treeSvg from "../../assets/images/Tree.svg";
import treeBgWhite from "../../assets/images/tree-bg-white@4x.png";
import greenTree from "../../assets/images/green_tree_white_bg_2x.png";
import quotes from "../../assets/images/vector@2x.svg";
import testimonials_img_1 from "../../assets/images/testimonial-1.png";
import testimonials_img_2 from "../../assets/images/testimonial-2.png";
import testimonials_img_3 from "../../assets/images/testimonial-3.png";
import testimonials_decorator from "../../assets/images/frame@2x.svg";
import team_member_1 from "../../assets/images/our-team-member-1.png";
import team_member_2 from "../../assets/images/our-team-member-2.png";
import team_member_3 from "../../assets/images/our-team-member-3.png";
import team_member_4 from "../../assets/images/our-team-member-4.png";
import newsletter_2 from "../../assets/images/newsletter-2.svg";
import newsletter_2_purple from "../../assets/images/newsletter-2-purple.svg";
import logo from "../../assets/images/ccpathways-logo.svg";
import logo_white from "../../assets/images/ccpathways-logo-white-letters.svg";
import logo_with_letters from "../../assets/images/ccpathways-logo-with-letters.svg";
import blue_hand from "../../assets/images/blue-hand.png";
import tree_icon from "../../assets/images/tree-icon.png";
import facebook from "../../assets/images/facebook-1@2x.svg";
import twitter from "../../assets/images/twitter-1@2x.svg";
import x_twitter from "../../assets/images/x-1@2x.png";
import youtube from "../../assets/images/youtube-1@2x.svg";
import instagram from "../../assets/images/instagram-1@2x.svg";
import line_6 from "../../assets/images/line-6-1@1x.svg";
import linkedin from "../../assets/images/linkedin-1@2x.svg";
import available_1 from '../../assets/images/available-positions-1.png';
import available_2 from '../../assets/images/available-positions-2.png';
import available_3 from '../../assets/images/available-positions-3.png';
import available_3_rounded from '../../assets/images/available-positions-3-rounded.svg';
import available_4 from '../../assets/images/available-positions-4.png';
import available_5 from '../../assets/images/available-positions-5.png';
import available_6 from '../../assets/images/available-positions-6.png';
import available_7_rounded from '../../assets/images/available-positions-7-rounded.svg';
import available_8_rounded from '../../assets/images/available-positions-8-rounded.svg';
import available_9_rounded from '../../assets/images/available-positions-9-rounded.svg';
import available_10_rounded from '../../assets/images/available-positions-10-rounded.svg';
import available_11_rounded from '../../assets/images/available-positions-11-rounded.svg';
import schedule from '../../assets/images/schedule.svg';
import purple_schedule from '../../assets/images/purple_schedule_4x.png';
import arrowSelected from '../../assets/images/purple_arrow_rounded_purple_bg_4x.png';
import arrowUnSelected from '../../assets/images/purple_arrow_rounded_light_blue_bg_4x.png';
import partner_img_1 from '../../assets/images/partner_1.svg';
import partner_img_2 from '../../assets/images/partner_2.svg';
import partner_img_3 from '../../assets/images/partner_3.svg';
import partner_img_4 from '../../assets/images/partner_4.svg';
import partner_img_5 from '../../assets/images/partner_5.svg';
import partner_img_6 from '../../assets/images/partner_6.svg';
import partner_img_7 from '../../assets/images/partner_7.svg';
import partner_img_8 from '../../assets/images/partner_8.svg';
import banner_decor_2_purple from '../../assets/images/purple_decor_2.svg';
import email_icon from "../../assets/images/mail-2.svg";
import email_unreaded_icon from "../../assets/images/mark_email_unread.svg";
import subscribe from "../../assets/images/subscribe-notification.svg";
import event_icon from "../../assets/images/event.svg";
import handsOnIcon from "../../assets/images/hands-on-icon-2x.png";
import expertMentorsIcon from "../../assets/images/expert-mentors-icon-2x.png";
import careerIcon from "../../assets/images/career-opportunities-icon-2x.png";

class FrequentlyQuestions extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <div class="frame-47">
            <div class="frame-31">
                <div class="frame">
                <div class="frequently-asked-questions circularstd-bold-prussian-blue-36px">
                    Frequently asked questions
                </div>
                </div>
            </div>
            <div class="frame-53">
                <div class="accordion active">
                <div class="accordion-title">
                    <p class="why-should-my-instit circularstd-medium-prussian-blue-20px">
                    Is prior experience required?
                    </p>
                    <img class="accordion-chevron" src="${chevron_up}" alt="chevron-up" />
                </div>
                <div class="accordion-description">
                    <p class="easy-donate-has-a-fr material-themebodylarge">
                    No, the program is designed for beginners with a strong interest in data analytics.
                    </p>
                </div>
                </div>
                <img class="line" src="${line}" alt="Line 6" />
                <div class="accordion">
                <div class="accordion-title">
                    <p class="why-should-my-instit circularstd-medium-prussian-blue-20px">
                    How long is the program?
                    </p>
                    <img class="accordion-chevron" src="${chevron_down}" alt="chevron-down" />
                </div>
                <div class="accordion-description">
                    <p class="easy-donate-has-a-fr material-themebodylarge">
                    The Apprenticeship program generally follows the community college schedule of classes. 
                    The programs are tailored to each student's current level of expertise at the time of 
                    joining the program. The program starts with taking students through an intense training 
                    program, after which they are paired with mentors and learn how to leverage the latest 
                    software tools and methodologies to develop production code.
                    </p>
                </div>
                </div>
                <img class="line" src="${line}" alt="Line 4" />
                <div class="accordion">
                <div class="accordion-title">
                    <p class="why-should-my-instit circularstd-medium-prussian-blue-20px">
                    What kind of projects will I work on?
                    </p>
                    <img class="accordion-chevron" src="${chevron_up}" alt="chevron-down" />
                </div>
                <div class="accordion-description">
                    <p class="easy-donate-has-a-fr material-themebodylarge">
                    <span class="span0-1 material-themebodylarge">
                    The projects that a student is assigned will depend on the area that the student would 
                    want to specialize in. As an example, one of students with a focus at the intersection 
                    of digital marketing and software development is working on building a gen-AI based 
                    marketing automation platform. A few of our students with a focus on Data Analysis are 
                    working on building the monitoring and metrics system for the platform, and, creating 
                    pipelines for downstream data analysis.
                    </p>
                </div>
                </div>
            </div>
        </div>
        `;
  }
}

class ScheduleAppointment extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <div class="customer-testimonials">
            <div class="frame">
                <div class="ready-to-get-started circularstd-bold-coconut-36px">Ready to embark on your tech journey?</div>
                <p class="get-started-for-free material-themebodylarge">
                  Stay up to date with the latest campaigns and news on <br>ccpathways.org by signing up to our Newsletter.
                </p>
            </div>
            <div class="frame-55">
                <a href="https://calendly.com/jean-easy-donate-org/easy-donate-org-intro" target="_blank">
                <div class="btn-5">
                    <div class="sign-up valign-text-middle circularstd-bold-white-16px">Schedule an Appoinment</div>
                </div>
                </a>
            </div>
        </div>
        `;
  }
}

class BannerSection extends HTMLElement {
  connectedCallback() {
    const title = this.getAttribute("title") || "The easy-donate ORG";
    const description =
      this.getAttribute("description") || "The easy-donate ORG";
    const btnText = this.getAttribute("btn-text");

    console.log(btnText);

    this.innerHTML = `
        <div class="inner-page">
            <div class="container">
                <div class="overlap-group4">
                    <div class="frame-96">
                        <div class="frame-95">
                            <p class="simple-affordable-a circularstd-bold-prussian-blue-45px">
                            ${title}
                            </p>
                            <p class="institutions-can-sta material-themebodylarge">
                            ${description}
                            </p>
                            ${btnText
                              ? `
                                    <div class="frame-55">
                                        <a href="/contact-us.html" target="_parent">
                                            <div class="btn-5">
                                                <div class="sign-up valign-text-middle circularstd-bold-white-16px">${btnText}</div>
                                            </div>
                                        </a>
                                    </div>
                                `
                              : ""}
                        </div>
                    </div>
                </div>
            </div>
            <div class="hand">
                <img src="${tree}" alt="">
            </div>
        </div>
        `;
  }
}

class Testimonials extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <link rel="stylesheet" type="text/css" href="../css/reusable-components.css" />
        <div class="customer-testimonials-component">
            <div class="container">
            <div class="frame">
                <div class="frame">
                <div class="customer-testimonials-1 circularstd-bold-coconut-36px">Customer testimonials</div>
                </div>
                <p class="here-is-what-some-of material-themebodylarge">Hear what some of our users have to say</p>
            </div>
            <div class="frame-99">
                <div class="frame-43">
                <img class="vector" src="${quotes}" alt="Vector" />
                <section class="slider-wrapper">
                    <ul class="slides-container" id="slides-container">
                    <li class="slide">
                        <p class="easy-donate-is-a-co material-themeheadlinesmall">
                        “ccpathways.org is a convenient app helps any generous heart to donate from anywhere. This app
                        fosters the generosity of time or dollars regardless of time or location of the
                        donors. Great and timely invention during pandemic!”
                        </p>
                        <div class="name material-themetitlelarge">Christina Arockiam</div>
                    </li>
                    <li class="slide">
                        <p class="easy-donate-is-a-co material-themeheadlinesmall">
                        “One App to fulfill our charity goals. Donating and volunteering is now made lot more easier
                        with ccpathways.org.”
                        </p>
                        <div class="name material-themetitlelarge">Charlie Nishant</div>
                    </li>
                    <li class="slide">
                        <p class="easy-donate-is-a-co material-themeheadlinesmall">
                        “The ccpathways.org app has given us the advantage to reach out our parishioners that for some
                        reason or another they couldn’t come to the Mass service but still want to make their Sunday
                        Offering.”
                        </p>
                        <div class="name material-themetitlelarge">Luis L. Miranda</div>
                    </li>
                    </ul>
                </section>
                </div>
                <div id="testimonials-selectors" class="frame-97">
                <img class="ellipse active" src="${testimonials_img_1}" alt="Ellipse 1" />
                <img class="ellipse" src="${testimonials_img_2}" alt="Ellipse 2" />
                <img class="ellipse" src="${testimonials_img_3}" alt="Ellipse 2" />
                </div>
            </div>
            <img class="frame-2" src="${testimonials_decorator}" alt="Frame" />
            </div>
        </div>
        `;
  }
}

class SimpleTestimonials extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <link rel="stylesheet" type="text/css" href="../css/reusable-components.css" />
        <div class="customer-testimonials-component">
            <div class="container">
            <div class="frame">
                <div class="frame">
                <div class="customer-testimonials-1 circularstd-bold-coconut-36px">Testimonials</div>
                </div>
                <p class="here-is-what-some-of material-themebodylarge">Here is what some of our users have to say</p>
            </div>
            <div class="frame-99">
                <div class="frame-43">
                <img class="vector" src="${quotes}" alt="Vector" />
                <section class="slider-wrapper">
                    <ul class="slides-container" id="slides-container">
                    <li class="slide">
                        <p class="easy-donate-is-a-co material-themeheadlinesmall">
                        “As someone who is brand new to the industry, I was incredibly 
                        grateful for the chance to gain hands-on experience and learn 
                        along with the team”
                        </p>
                        <br>
                        <div class="name material-themetitlelarge"> Gabriela Rollins, Data Science Apprentice</div>
                    </li>
                    </ul>
                </section>
                </div>
            </div>
            </div>
        </div>
        `;
  }
}

// class OurTeam extends HTMLElement {
//   constructor() {
//     super();
//     this.testimonials = [
//         {
//             photo: team_member_1,
//             text: "“Dedicated to merging success, social impact, and innovation, inspiring change, and rewriting the rules for a brighter future”",
//             author: "Tennyson Williams, CEO",
//         },
//         {
//             photo: team_member_2,
//             text: "“Tech enthusiast shaping a better world. Leads AWS team, educates at SJCC, and champions ccpathways.org  for student opportunities”",
//             author: "Sanjay Dorairaj, Board Member",
//         },
//         // {
//         //     text: "“Learning at CCPathways taught me tech hands-on, Python, HTML, CSS, and more. Grateful for the experience, the team, and the knowledge gained”",
//         //     author: "Isadora Vasconcelos, Front End Apprentice",
//         // },
//         {
//           photo: team_member_4,
//           text: "“Project Manager at ioet, Quito. Led web app development, managed teams, enhanced processes. Co-founder of Klav, SaaS startup”",
//           author: "Jean Calderon, Program Director",
//         },
//     ];
//     this.currentIndex = 0;
//     this.transitionDuration = 1000;
//   }

//   connectedCallback() {
//     this.innerHTML = `
//     <link rel="stylesheet" type="text/css" href="../css/reusable-components.css" />
//     <div class="customer-testimonials">
//         <div class="frame">
//             <div class="ready-to-get-started circularstd-bold-coconut-36px">Our team</div>
//             <p class="get-started-for-free material-themebodylarge">
//               The ccpathways.org team consists of experts from various domains including <br>consumer finance, technology,
//               security and business consulting.
//             </p>
//         </div>
//         <div class="customer-testimonials-component frame-55">
//             <div class="frame-98 frame-9888">
//               <div class="frame-15-1">
//                 <div class="overlap-group-1">
//                   <div class="rectangle-22"></div>
//                   <img class="image" alt="image 68" />
//                 </div>
//               </div>
//               <p class="testimonial-text as-someone-who-is-b"></p>
//               <p class="testimonial-author circularstd-medium-white-24px"></p>
//             </div>
//         </div>
//     </div>
//     `;

//     this.showCurrentTestimonial();
//     this.adjustTestimonialText();
//     this.initTestimonialChange();
//   }

//   showCurrentTestimonial() {
//       const testimonialText = this.querySelector(".testimonial-text");
//       const testimonialAuthor = this.querySelector(".testimonial-author");
//       const testimonialPhoto = this.querySelector(".image");
//       const currentTestimonial = this.testimonials[this.currentIndex];

//       testimonialText.innerHTML = currentTestimonial.text;
//       testimonialAuthor.innerText = currentTestimonial.author;
//       testimonialPhoto.src = currentTestimonial.photo;
//   }

//   adjustTestimonialText() {
//       const testimonialText = this.querySelector(".testimonial-text");
//       const currentTestimonial = this.testimonials[this.currentIndex];

//       if (window.innerWidth <= 768) {
//           testimonialText.innerHTML = currentTestimonial.text.replace(/<br>/g, ' ');
//       } else {
//           testimonialText.innerHTML = currentTestimonial.text;
//       }
//   }

//   initTestimonialChange() {
//       setInterval(() => this.changeTestimonial(), 5000);
//   }

//   changeTestimonial() {
//       const testimonialElement = this.querySelector(".customer-testimonials-component");
//       testimonialElement.style.transition = `opacity ${this.transitionDuration}ms ease-in-out`;
//       testimonialElement.style.opacity = 0;

//       setTimeout(() => {
//           this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
//           this.showCurrentTestimonial();
//           this.adjustTestimonialText();
//           testimonialElement.style.opacity = 1;
//       }, this.transitionDuration);
//   }
// }

class OurTeam extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <link rel="stylesheet" type="text/css" href="../css/reusable-components.css" />
        <div class="our-team-component-container">
          <div class="our-team-component">
              <div>
              <div><div class="our-team-title circularstd-bold-coconut-36px">Our team</div></div>
              <p class="the-easy-donate-team material-themebodylarge">
                  The easy-donate team consists of experts from various domains including consumer finance, technology,
                  security and business consulting.
              </p>
              </div>
              <div class="frame-164">
              <div class="frame-15">
                  <img class="quote-decor-2" src="${banner_decor_2_purple}" alt="decoration image" />
                  <div class="frame-150">
                  <div class="frame-15-1">
                      <div class="overlap-group-1">
                      <div class="rectangle-22"></div>
                      <img class="image" src="${team_member_1}" alt="image 68" />
                      </div>
                  </div>
                  <div class="frame-148">
                      <div class="our-team-member-name material-themetitlemedium">Tennyson Williams</div>
                      <div class="our-team-member-description material-themeoverline">CEO</div>
                      <div class="our-team-member-bios material-themeoverline">“Dedicated to merging success, social impact, and innovation, inspiring change, and rewriting the rules for a brighter future”</div>
                  </div>
                  </div>
              </div>
              <div class="frame-15">
                  <img class="quote-decor-2" src="${banner_decor_2_purple}" alt="decoration image" />
                  <div class="frame-150">
                  <div class="frame-15-1">
                      <div class="overlap-group-1">
                      <div class="rectangle-22"></div>
                      <img class="image" src="${team_member_2}" alt="image 68" />
                      </div>
                  </div>
                  <div class="frame-148">
                      <div class="our-team-member-name material-themetitlemedium">Sanjay Dorairaj</div>
                      <div class="our-team-member-description material-themeoverline">Founder, ccpathways.org</div>
                      <div class="our-team-member-bios material-themeoverline">“Tech enthusiast shaping a better world. Leads AWS team, educates at SJCC, and champions ccpathways.org  for student opportunities”</div>
                  </div>
                  </div>
              </div>
              <div class="frame-15">
                  <img class="quote-decor-2" src="${banner_decor_2_purple}" alt="decoration image" />
                  <div class="frame-150">
                  <div class="frame-15-1">
                      <div class="overlap-group-1">
                      <div class="rectangle-22"></div>
                      <img class="image" src="${team_member_4}" alt="image 68" />
                      </div>
                  </div>
                  <div class="frame-148">
                      <div class="our-team-member-name material-themetitlemedium">Jean Calderon</div>
                      <div class="our-team-member-description material-themeoverline">Program Director</div>
                      <div class="our-team-member-bios material-themeoverline">“Project Manager at ioet, Quito. Led web app development, managed teams, enhanced processes. Co-founder of Klav, SaaS startup”</div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
          </div>
        `;
  }
}

class NewsLetter extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
          <link rel="stylesheet" type="text/css" href="../css/reusable-components.css" />
          <div class="newsletter-component">
              <script src="ck.5.js"></script>
              <form action="https://app.convertkit.com/forms/3492095/subscriptions" method="post" data-sv-form="3492095"
                  data-uid="79aef3ec37" data-format="inline" data-version="5"
                  data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}">
                  <div class="frame-960">
                      <div class="container">
                          <div class="frame-48">
                              <p class="fair-prices-no-hidden-fees circularstd-bold-prussian-blue-36px">
                                Subscribe here for free
                              </p>
                              <p class="we-help-you-create-reusable material-themebodylarge">
                                  Stay up to date with the latest campaigns and features on ccpathways.org by signing up to our Newsletter.
                              </p>
                              <!--ELEMENTS-->
                              <div data-style="clean">
                                  <ul class="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
                                  <div data-element="fields" data-stacked="false" class="seva-fields formkit-fields">
                                      <div class="formkit-field">
                                          <input class="newsletter-field" placeholder="Email address" required="" type="email"
                                              name="email_address" id="newsletter">
                                      </div>
                                  </div>
                              </div>
                              <button data-element="submit" class="btn-6 sign-up valign-text-middle circularstd-bold-white-16px">
                                  <span class="">Subscribe</span>
                              </button>
                              <!--ELEMENTS-->
                              <p class="we-help-you-create-reusable material-themebodylarge">
                              We HATE spam. Your email address is 100% secure.
                              </p>
                          </div>
                          <div class="frame-540">
                              <img src="${newsletter_2_purple}" alt="newsletter">
                          </div>
                      </div>
                  </div>
              </form>
          </div>
      `;
  }
}

class NavBar extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
    <div class="wrapper">
        <div class="wrapper-header">
            <label for="checkbox_toggle" class="hamburger">&#9776;</label>
            <a href="/" class="logo-mobile-navbar"><div class="logo"> <img class="logo-img" src="${logo}" alt=""> </div></a>
            <a href="/" class="logo-web-navbar"><div class="logo"> <img class="logo-img" src="${logo_with_letters}" alt=""> </div></a>
            <nav class="navbar">
                <div class="container-nav">
                <ul class="nav-links">
                    <input type="checkbox" id="checkbox_toggle" />
                    <form class="logo-mobile-view-donate" action="https://www.paypal.com/donate" method="post" target="_top">
                      <input type="hidden" name="hosted_button_id" value="5HPZHD9HHZH86" />
                      <a href="/" target="_blank">
                        <div class="schedule-call">
                          <button class="btn-schedule-call" type="submit">Donate</button>
                        </div>
                      </a>
                    </form>
                    <div class="menu">
                    <li class="home-options">
                        <a href="/" class="home-option-text">Home</a>
                    </li>
                    <li><a href="/community-colleges.html" class="apprenticeship-link">Apprenticeship Program</a></li>
                    <li>
                        <a href="#" class="contact-us-link">Contact Us</a>
                    </li>
                    </div>
                </ul>
                </div>
            </nav>
            <div class="right-wrapper">
                <div style="margin-right: 20px;"></div>
                <form action="https://www.paypal.com/donate" method="post" target="_top">
                  <input type="hidden" name="hosted_button_id" value="5HPZHD9HHZH86" />
                  <a href="/" target="_blank">
                    <div class="schedule-call">
                      <button class="btn-schedule-call" type="submit">Donate</button>
                    </div>
                  </a>
                </form>
            </div>
        </div>
    </div>
    `;
  }
}

class Footer extends HTMLElement {
  connectedCallback() {
    const year = new Date().getFullYear();
    this.innerHTML = `
        <div class="footer">
        <div class="container">
          <div class="frame-45">
            <div class="frame-54-1">
              <div>
                <div class="above">
                  <div class="logo logo-web"> <img class="logo-img" src="${logo_white}" alt=""> </div>
                  <div class="logo logo-mobile"> <img class="logo-img" src="${logo_white}" alt=""> </div>
                  <div class="easy-legal-info">
                    <p class="easy-donate-inc-20 material-themebodymedium">
                      ccpathways.org is a tax exempt 501c3 Non-Profit-Institution.</br>
                      Legal Name: EasyToDonate.us</br>
                      DBA: ccpathways.org</br>
                      EIN: 93-1708013
                    </p>
                  </div>
                  <div class="legal-line"> <img src="${line_6}" alt="Line 6" /> </div>
                </div>
              </div>
              <div class="frame-dinamic">
                <div class="frame-400">
                  <div class="footer-link material-themebodymedium">
                    <a href="/community-colleges.html">For Community Colleges</a>
                  </div>
                </div>
                <div class="frame-400">
                  <div class="footer-link material-themebodymedium">
                    <a href="#" class="contact-us-link">Contact Us</a>
                  </div>
                  <div class="footer-link material-themebodymedium">
                    <a class="about-us-link" href="#">About Us</a>
                  </div>
                </div>
              </div>
              <div>
                <div class="frame-400 frame-400-social">
                  <div class="quick-links material-themetitlemedium">Follow Us</div>
                  <div class="social">
                    <div class="frame-56">
                      <a href="https://www.facebook.com/profile.php?id=61552129239424" target="_blank">
                        <img class="facebook" src="${facebook}" alt="facebook" />
                      </a>
                      <a href="https://www.instagram.com/ccpathwaysorg/" target="_blank">
                        <img class="instagram" src="${instagram}" alt="instagram" />
                      </a>
                      <a href="https://www.linkedin.com/company/ccpathways-org/" target="_blank">
                        <img class="linkedin" src="${linkedin}" alt="linkedin" />
                      </a>
                      <a href="https://twitter.com/CCpathways_org" target="_blank">
                        <img class="twitter" src="${x_twitter}" alt="twitter" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="frame-57">
            <img class="line" src="${line_6}" alt="Line 6" />
            <left>
              <p class="easy-donate-inc-20 material-themebodymedium">
                @EasyToDonate Inc ${year}. All rights reserved.
              </p>
            </left>
          </div>
        </div>
      </div>
        `;
  }
}

class FAQNonProfit extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <div class="frame-47">
            <div class="frame-31">
                <div class="frame">
                <div class="frequently-asked-questions circularstd-bold-prussian-blue-36px">
                    Frequently asked questions
                </div>
                </div>
            </div>
            <div class="frame-53">
                <div class="accordion active">
                <div class="accordion-title">
                    <p class="why-should-my-instit circularstd-medium-prussian-blue-20px">
                    What are the benefits of donating to a foreign nonprofit through your institution?
                    </p>
                    <img class="accordion-chevron" src="${chevron_up}" alt="chevron-up" />
                </div>
                <div class="accordion-description">
                    <p class="easy-donate-has-a-fr material-themebodylarge">
                    Tax exemption: Donations to foreign nonprofits are generally not tax-deductible for 
                    US citizens, but donations through our institution are.
                    <br><br>
                    Transparency and accountability: We carefully vet all foreign nonprofits we partner 
                    with to ensure they are reputable and donations are used for their intended purposes
                    </p>
                </div>
                </div>
                <img class="line" src="${line}" alt="Line 6" />
                <div class="accordion">
                <div class="accordion-title">
                    <p class="why-should-my-instit circularstd-medium-prussian-blue-20px">
                    How does your institution ensure that my donation will be used for its intended purpose?
                    </p>
                    <img class="accordion-chevron" src="${chevron_down}" alt="chevron-down" />
                </div>
                <div class="accordion-description">
                    <p class="easy-donate-has-a-fr material-themebodylarge">
                    We have a rigorous vetting process for foreign nonprofits, and review their financial records, 
                    programs and services, and governance structure. We also require partners to sign a grant 
                    agreement outlining how they will use our funds.
                    </p>
                </div>
                </div>
                <img class="line" src="${line}" alt="Line 4" />
                <div class="accordion">
                <div class="accordion-title">
                    <p class="why-should-my-instit circularstd-medium-prussian-blue-20px">
                    What are the fees associated with donating through your institution?
                    </p>
                    <img class="accordion-chevron" src="${chevron_up}" alt="chevron-down" />
                </div>
                <div class="accordion-description">
                    <p class="easy-donate-has-a-fr material-themebodylarge">
                    <span class="span0-1 material-themebodylarge">
                    We charge a small processing fee on all donations to cover the costs of running 
                    our organization and ensuring your donation is used for its intended purpose.
                    </p>
                </div>
                </div>
                <img class="line" src="${line}" alt="Line 6" />
                <div class="accordion">
                <div class="accordion-title">
                    <p class="why-should-my-instit circularstd-medium-prussian-blue-20px">
                    How can I donate to a foreign nonprofit through your institution?
                    </p>
                    <img class="accordion-chevron" src="${chevron_down}" alt="chevron-down" />
                </div>
                <div class="accordion-description">
                    <p class="easy-donate-has-a-fr material-themebodylarge">
                    You can look up the foreign non-profit on the easy-donate.com.
                    </p>
                </div>
                </div>
            </div>
        </div>
        `;
  }
}

class AvailablePositions extends HTMLElement {
  connectedCallback() {
      this.innerHTML = `
      <div class="frame-95-1">
          <div class="frame">
              <div class="frequently-asked-questions circularstd-bold-prussian-blue-36px">
              Available Programs
              </div>
          </div>
          <div class="frame-1">
              <div class="frame-54">
                  <a href="/front-end-details.html">
                      <div id="card-1" class="card border-unselected" onmouseover='updateSrc("card-1", "${arrowSelected}")' onmouseout='updateSrc("card-1", "${arrowUnSelected}")'>
                          <div class="frame-9">
                              <img class="schedule" src="${available_7_rounded}" type="image/webp" alt="available_7_rounded" />
                          </div>
                          <div class="frame-8">
                              <p class="dierre-fort-sda-chur circularstd-medium-prussian-blue-20px">
                                  <span class="circularstd-medium-prussian-blue-20px">Front-end Development</span>
                              </p>
                              <div class="frame-111">
                                  <img class="schedule" src="${purple_schedule}" alt="schedule" />
                                  <div class="valign-text-bottom gilroy-medium-themerefprimaryprimary6060-12px">18 Months</div>
                              </div>
                              <p class="amet-minim-mollit-no gilroy-medium-oxford-blue-16px">
                                  <span class="gilroy-medium-oxford-blue-16px">Dive into the world of Flutter with BloC to create engaging and responsive user interfaces.</span>
                              </p>
                          </div>
                          <img id="card-1-arrow-img" class="arrow-img" src="${arrowUnSelected}" />
                      </div>
                  </a>

                  <a href="/it-details.html">
                      <div id="card-2" class="card border-unselected" onmouseover='updateSrc("card-2", "${arrowSelected}")' onmouseout='updateSrc("card-2", "${arrowUnSelected}")'>
                          <div class="frame-9">
                              <img class="schedule" src="${available_8_rounded}" type="image/webp" alt="available_8_rounded" />
                          </div>
                          <div class="frame-8">
                              <p class="dierre-fort-sda-chur circularstd-medium-prussian-blue-20px">
                                  <span class="circularstd-medium-prussian-blue-20px">IT/Cybersecurity</span>
                              </p>
                              <div class="frame-111">
                                  <img class="schedule" src="${purple_schedule}" alt="schedule" />
                                  <div class="valign-text-bottom gilroy-medium-themerefprimaryprimary6060-12px">18 Months</div>
                              </div>
                              <p class="amet-minim-mollit-no gilroy-medium-oxford-blue-16px">
                                  <span class="gilroy-medium-oxford-blue-16px">Join us to support our giver-focused platform's IT infrastructure.</span>
                              </p>
                          </div>
                          <img id="card-2-arrow-img" class="arrow-img" src="${arrowUnSelected}" />
                      </div>
                  </a>

                  <a href="/data-analytics-details.html">
                      <div id="card-3" class="card border-unselected" onmouseover='updateSrc("card-3", "${arrowSelected}")' onmouseout='updateSrc("card-3", "${arrowUnSelected}")'>
                          <div class="frame-9">
                              <img class="schedule" src="${available_3_rounded}" type="image/webp" alt="available_3_rounded" />
                          </div>
                          <div class="frame-8">
                              <p class="dierre-fort-sda-chur circularstd-medium-prussian-blue-20px">
                                  <span class="circularstd-medium-prussian-blue-20px">Data Analytics</span>
                              </p>
                              <div class="frame-111">
                                  <img class="schedule" src="${purple_schedule}" alt="schedule" />
                                  <div class="valign-text-bottom gilroy-medium-themerefprimaryprimary6060-12px">18 Months</div>
                              </div>
                              <p class="amet-minim-mollit-no gilroy-medium-oxford-blue-16px">
                                  <span class="gilroy-medium-oxford-blue-16px">Learns to use AWS and other platform services to collect, store, analyze, and visualize data.</span>
                              </p>
                          </div>
                          <img id="card-3-arrow-img" class="arrow-img" src="${arrowUnSelected}" />
                      </div>
                  </a>
              </div>
              <div class="frame-54">
                  <div id="card-4" class="card border-unselected">
                      <div class="frame-9">
                          <img class="schedule" src="${available_9_rounded}" type="image/webp" alt="available_9_rounded" />
                      </div>
                      <div class="frame-8">
                          <p class="dierre-fort-sda-chur circularstd-medium-prussian-blue-20px">
                              <span class="circularstd-medium-prussian-blue-20px">Accounting</span>
                              <span class="circularstd-medium-red-20px"> - Comming Soon</span>
                          </p>
                          <div class="frame-111">
                              <img class="schedule" src="${purple_schedule}" alt="schedule" />
                              <div class="valign-text-bottom gilroy-medium-themerefprimaryprimary6060-12px">18 Months</div>
                          </div>
                          <p class="amet-minim-mollit-no gilroy-medium-oxford-blue-16px">
                              <span class="gilroy-medium-oxford-blue-16px">Hands-on experience in financial analysis, reporting, and compliance.</span>
                          </p>
                      </div>
                      <img id="card-4-arrow-img" class="arrow-img" src="${arrowUnSelected}" />
                  </div>

                  <a href="/marketing-details.html">
                      <div id="card-5" class="card border-unselected" onmouseover='updateSrc("card-5", "${arrowSelected}")' onmouseout='updateSrc("card-5", "${arrowUnSelected}")'>
                          <div class="frame-9">
                              <img class="schedule" src="${available_10_rounded}" type="image/webp" alt="available_10_rounded" />
                          </div>
                          <div class="frame-8">
                              <p class="dierre-fort-sda-chur circularstd-medium-prussian-blue-20px">
                                  <span class="circularstd-medium-prussian-blue-20px">Marketing</span>
                              </p>
                              <div class="frame-111">
                                  <img class="schedule" src="${purple_schedule}" alt="schedule" />
                                  <div class="valign-text-bottom gilroy-medium-themerefprimaryprimary6060-12px">18 Months</div>
                              </div>
                              <p class="amet-minim-mollit-no gilroy-medium-oxford-blue-16px">
                                  <span class="gilroy-medium-oxford-blue-16px">On-the-job experience in market research, content creation, and social media managemen.t</span>
                              </p>
                          </div>
                          <img id="card-5-arrow-img" class="arrow-img" src="${arrowUnSelected}" />
                      </div>
                  </a>
                  
                  <div id="card-6" class="card border-unselected">
                      <div class="frame-9">
                          <img class="schedule" src="${available_11_rounded}" type="image/webp" alt="available_11_rounded" />
                      </div>
                      <div class="frame-8">
                          <p class="dierre-fort-sda-chur circularstd-medium-prussian-blue-20px">
                              <span class="circularstd-medium-prussian-blue-20px">Legal</span>
                              <span class="circularstd-medium-red-20px"> - Comming Soon</span>
                          </p>
                          <div class="frame-111">
                              <img class="schedule" src="${purple_schedule}" alt="schedule" />
                              <div class="valign-text-bottom gilroy-medium-themerefprimaryprimary6060-12px">18 Months</div>
                          </div>
                          <p class="amet-minim-mollit-no gilroy-medium-oxford-blue-16px">
                              <span class="gilroy-medium-oxford-blue-16px">Hands-on in corporate law, intellectual property, and regulatory compliance.</span>
                          </p>
                      </div>
                      <img id="card-6-arrow-img" class="arrow-img" src="${arrowUnSelected}" />
                  </div>
              </div>
          </div>
      </div>
      `;
  }
}

class BecomeSection extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="become-component">
        <div class="be-co-frame">
          <div class="become-item bg-green">
            <div class="div-2">
              <p class="text-wrapper-1">Community Colleges</p>
              <p class="become-description">
                Empower Students: Explore Our College Apprenticeship & Internship Program
              </p>
              <a href="/community-colleges.html">
                <button class="become-btn"><div class="text-wrapper-2">Get Started</div></button>
              </a>
            </div>
            <img class="group" src="${greenTree}" />
          </div>
          <div class="become-item bg-purple">
            <div class="div-2">
              <div class="text-wrapper-1">Become an employment partner</div>
              <p class="become-description">
                Elevate Together, Powering Success in Talent Recruitment Initiatives.
              </p>
              <a href="https://forms.zohopublic.com/easydonateinc/form/Partners/formperma/OKJYlrLb0oPP6CC2RR5vZdQb0qEtXoCItO4BrbT64XI">
                <button class="become-btn"><div class="text-wrapper-2">Get Started</div></button>
              </a>
            </div>
            <img class="group" src="${treeBgWhite}" />
          </div>
        </div>
      </div>
  `;
  }
}

class VisionAndMission extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="vision-and-mission-component">
        <div class="vi-mi-frame">
          <div class="div-1">
            <div class="div-2">
              <div class="rectangle-1"></div>
              <div class="rectangle-2"></div>
              <div class="rectangle-3"></div>
              <div class="rectangle-4"></div>
              <img class="group-1" src="${treeSvg}"/>
            </div>
            <div class="div-3">
              <div class="div-4">
                <div class="text-wrapper-title">OUR VISION</div>
                <p class="mission-vision-p">
                To create a world where every individual, regardless 
                of their background or circumstances, has equal access 
                to opportunities for skill development, personal growth, 
                and workforce success, fostering a global community 
                empowered to realize their full potential and contribute 
                positively to society.
                </p>
              </div>
              <div class="div-4">
                <div class="text-wrapper-title">OUR MISSION</div>
                <p class="mission-vision-p">
                  <span class="mission-vision-span">
                  To Empower community college students to navigate their 
                  college career by providing accessible resources, support, and connection.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <img class="mission-vision-img" src="${treeBgWhite}" />
        </div>
      </div>
    `;
  }
}

class OurPartners extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="our-partners-component">
        <div class="opc">
          <div class="frame-1">
            <div class="our-partners-wrapper">
              <div class="our-partners">
                OUR PARTNERS
              </div>
            </div>
          </div>
          <div class="div-1">
            <div class="frame-wrapper">
              <div class="frame-2">
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_1}" />
                </div>
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_2}" />
                </div>
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_3}" />
                </div>
              </div>
            </div>
            <div class="frame-wrapper">
              <div class="frame-2">
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_4}" />
                </div>
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_5}" />
                </div>
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_6}" />
                </div>
              </div>
            </div>
            <div class="frame-wrapper">
              <div class="frame-2">
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_7}" />
                </div>
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_8}" />
                </div>
                <div class="image-wrapper">
                  <img class="partner-image" src="${partner_img_2}" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

class ExtraNavBar extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="extra-navbar-component">
        <div class="extra-navbar">
          <div class="buttons">
            <a href="mailto:support@easy-donate.com">
              <div class="frame-4">
                <img class="en-img" src="${email_icon}" />
                <div class="text-wrapper">info@ccpathways.org</div>
              </div>
            </a>
            <div class="frame-1">
              <a href="" class="subscribe-option-link">
                <div class="div-1">
                  <img class="en-img" src="${subscribe}" />
                  <div class="text-wrapper">Subscribe</div>
                </div>
              </a>
              <a href="https://calendly.com/jean-easy-donate-org/easy-donate-org-intro" target="_blank">
                <div class="div-1">
                  <img class="en-img" src="${event_icon}" />
                  <div class="text-wrapper">Schedule a Call</div>
                </div>
              </a>
            </div>
          </div>
          <hr>
        </div>
      </div>
    `;
  }
}

class BannerSection2 extends HTMLElement {
  connectedCallback() {
    const title = this.getAttribute("title") || "The easy-donate ORG";
    const description = this.getAttribute("description") || "The easy-donate ORG";
    const btnText = this.getAttribute("btn-text");

    this.innerHTML = `
      <div class="banner-section-2">
        <div class="overlap-group-wrapper">
          <div class="overlap-group">
            <div class="hand">
              <img class="group-4" src="${tree}" alt="">
            </div>
            <div class="frame-20">
              <p class="text-wrapper-8 circularstd-black-primary10-45px-w900">
                ${title}
              </p>
              <div class="frame-14">
                <p class="text-wrapper-9">
                  ${description}
                </p>
                ${btnText
                  ? `
                        <div class="frame-55">
                            <a href="/contact-us.html" target="_parent">
                                <div class="btn-5">
                                    <div class="sign-up valign-text-middle circularstd-bold-white-16px">${btnText}</div>
                                </div>
                            </a>
                        </div>
                    `
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

class IconsSection extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="icons-overlapping-section">
        <div class="frame-21">
          <div class="frame-22">
            <div class="icon-wrapper bg-purple">
              <img class="img-3" src="${handsOnIcon}" />
            </div>
            <div class="frame-23">
              <div class="text-wrapper-10">Hands-On Learning</div>
              <p class="text-wrapper-11">
                Learn by doing with on-the-job training in real software technology
                projects.
              </p>
            </div>
          </div>
          <div class="frame-22">
            <div class="icon-wrapper bg-red">
              <img class="img-3" src="${expertMentorsIcon}" />
            </div>
            <div class="frame-23">
              <div class="text-wrapper-10">Expert Mentors</div>
              <p class="text-wrapper-11">
                Get guidance and insights from experienced professionals in the field.
              </p>
            </div>
          </div>
          <div class="frame-22">
            <div class="icon-wrapper bg-green">
              <img class="img-3" src="${careerIcon}" />
            </div>
            <div class="frame-23">
              <div class="text-wrapper-10">Career Opportunities</div>
              <p class="text-wrapper-11">
                Open doors to meaningful careers in the ever-growing tech industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define("main-footer", Footer);
customElements.define("main-navbar", NavBar);
customElements.define("frequently-questions", FrequentlyQuestions);
customElements.define("schedule-appointment", ScheduleAppointment);
customElements.define("banner-section", BannerSection);
customElements.define("banner-section-2", BannerSection2);
customElements.define("icons-section", IconsSection);
customElements.define("customer-testimonials", Testimonials);
customElements.define("simple-testimonials", SimpleTestimonials);
customElements.define("our-team", OurTeam);
customElements.define("news-letter", NewsLetter);
customElements.define("faq-non-profit", FAQNonProfit);
customElements.define('available-positions', AvailablePositions);
customElements.define("become-section", BecomeSection);
customElements.define("vision-and-mission", VisionAndMission);
customElements.define("our-partners", OurPartners);
customElements.define("extra-navbar", ExtraNavBar);