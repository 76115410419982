import chevron_down from '../../assets/images/chevron-down@2x.svg';
import chevron_up from '../../assets/images/chevron-up.svg';

/* FREQUENTLY QUESTIONS */
const accordions = document.querySelectorAll('.accordion');

accordions.forEach((item) => {
    item.addEventListener('click', frequentlyQuestions);
});

function frequentlyQuestions(e) {
    const isActive = Array.from(e.target.classList).indexOf("active") >= 0
    if (!e.target.classList.contains("accordion")) return
    
    Array.from(e.target.children[0].children).forEach(el => {
        if (!el.classList.contains("accordion-chevron")) return
        if (isActive) {
            e.target.classList = "accordion"
            el.src = chevron_down
        } else {
            e.target.classList = "accordion active"
            el.src = chevron_up
        }
    })
}
/* FREQUENTLY QUESTIONS */

/* NAVBAR AND FOOTER */
const aboutUsLink = document.querySelectorAll('.about-us-link');

aboutUsLink.forEach((item) => {
  item.addEventListener('click', (event) => {
    event.preventDefault();
    window.location.href = '/about.html';
  });
})

const contactUsLink = document.querySelectorAll('.contact-us-link');

contactUsLink.forEach((item) => {
  item.addEventListener('click', (event) => {
    event.preventDefault();
    window.location.href = '/contact-us.html';
  });
})

const subscribeOption = document.querySelectorAll('.subscribe-option-link');

subscribeOption.forEach((item) => {
  item.addEventListener('click', (event) => {
    event.preventDefault();
    window.location.href = '/contact-us.html?scroll=true';
  });
})
/* NAVBAR AND FOOTER */

/* TESTIMONIALS */
const testimonialsSelectors = document.querySelector('#testimonials-selectors');
if (testimonialsSelectors != null) {
  Array.from(testimonialsSelectors.children).forEach((e) => {
    e.addEventListener('click', navigateToTestimonial);
  });
}

function navigateToTestimonial(e) {
  const slide = document.querySelector('.slide');
  const slidesContainer = document.getElementById('slides-container');
  const selectors = Array.from(testimonialsSelectors.children);
  const indexSelector = selectors.indexOf(e.target);
  const slideWidth = slide.clientWidth * indexSelector;

  slidesContainer.scrollLeft = slideWidth;
  selectors.forEach((e) => (e.classList = 'ellipse'));
  e.target.classList = 'ellipse active';
}
/* TESTIMONIALS */