import phone from "../../assets/images/phone-icon@1x.png";
import email from "../../assets/images/email-icon@1x.png";
import message from "../../assets/images/messages-icon@1x.png";
import location from "../../assets/images/location-icon@1x.png";

class ContactInformation extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <div class="frame-94" id="contact-information-container">
        <div class="frame-190">
          <div class="overlap-group-1">
            <div class="contact-information-title circularstd-bold-prussian-blue-36px">
              <span class="circularstd-bold-prussian-blue-36px">Contact information</span>
            </div>
            <p class="contact-information-text gilroy-medium-oxford-blue-16px">
              <span class="gilroy-medium-oxford-blue-16px">Fill out the form and our team will get back to you
                soon.</span>
            </p>
            <div class="frame-630518">
              <div class="frame-22-1">
                <img class="frame-219" src="${phone}" alt="Frame 219" />
                <div class="contact-information-info-label">
                  <span class="gilroy-semi-bold-prussian-blue-16px">1-‪(408) 890-6620</span>
                </div>
              </div>
              <div class="frame-22-1">
                <img class="frame-219" src="${email}" alt="Frame 219" />
                <div class="contact-information-info-label gilroy-semi-bold-prussian-blue-16px">
                  <a href="mailto:support@easy-donate.com">
                    <span class="gilroy-semi-bold-prussian-blue-16px">info@ccpathways.org</span>
                  </a>
                </div>
              </div>
              <div class="frame-22-1">
                <img class="frame-219" src="${message}" alt="Frame 219" />
                <div class="frame-123">
                  <div class="contact-information-info-label gilroy-semi-bold-prussian-blue-16px">
                    <span class="gilroy-semi-bold-prussian-blue-16px">Help Center</span>
                  </div>
                  <p class="contact-hours gilroy-medium-oxford-blue-14px">
                    <span class="gilroy-medium-oxford-blue-14px">Monday - Sunday: 8:00 AM - 8:00 PM</span>
                  </p>
                </div>
              </div>
              <div class="frame-22-1">
                <img class="frame-219" src="${location}" alt="Frame 219" />
                <div class="frame-123">
                  <div class="contact-information-info-label gilroy-semi-bold-prussian-blue-16px">
                    <span class="gilroy-semi-bold-prussian-blue-16px">Address</span>
                  </div>
                  <p class="address-1 address-3 gilroy-medium-oxford-blue-14px">
                    <span class="gilroy-medium-oxford-blue-14px">1062 Lincoln Ave #1053, San Jose, CA 95125</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div class="contact-form">
          <!-- Contact Form -->
          <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
          <form action="https://app.convertkit.com/forms/3070829/subscriptions" class="seva-form formkit-form" method="post"
            data-sv-form="3070829" data-uid="9e8e111917" data-format="inline" data-version="5"
            data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}">
            <div data-style="image">
              <!-- <div data-element="column" class="formkit-column" style="background-color: rgb(51, 51, 51); height: 480px;">
                  <div class="formkit-background"
                    style="background-image: url(&quot;https://embed.filekitcdn.com/e/vwWPE1iLayip8trFVTaQog/nQ18eindFSAjKVz33FSxMS&quot;); opacity: 1;">
                  </div>
                  <div class="formkit-header" data-element="header"
                    style="color: rgb(244, 243, 247); font-size: 36px; font-weight: 700;">
                    <h2>​</h2>
                  </div>
                  <div class="formkit-subheader" data-element="subheader" style="color: rgb(250, 250, 250); font-size: 22px;">
                    <p>​</p>
                  </div>
                </div> -->
              <div data-element="column" class="formkit-column">
                <ul class="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
                <div data-element="fields" class="">
                  <div class="complete-name-contact-form">
                    <div class="formkit-field name-contact-form"><input class="formkit-input" aria-label="First Name"
                        name="fields[first_name]" required="" placeholder="First Name" type="text">
                    </div>
                    <div class="formkit-field name-contact-form"><input class="formkit-input" aria-label="Last Name"
                        name="fields[last_name]" required="" placeholder="Last Name" type="text">
                    </div>
                  </div>
                  <div class="formkit-field"><input class="formkit-input" aria-label="Organization Name"
                      name="fields[organization_names]" placeholder="Organization Name" type="text">
                  </div>
                  <div class="formkit-field"><input class="formkit-input" name="email_address" aria-label="Email "
                      placeholder="Email " required="" type="email">
                  </div>
                  <div class="formkit-field"><input class="formkit-input" aria-label="Phone Number"
                      name="fields[phone_number]" placeholder="Phone Number" type="text">
                  </div>
                  <div class="formkit-field"><input class="formkit-input" aria-label="Additional comments (Optional)"
                      name="fields[point_of_contact]" placeholder="Additional comments (Optional)" type="text">
                  </div><button data-element="submit" class="formkit-submit submit-contact-info">
                    <div class="formkit-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div><span class="">Submit</span>
                  </button>
                </div>
              </div>
          </form>
        </div>
      </div>
        `;

    document.addEventListener('DOMContentLoaded', function () {
      const urlParams = new URLSearchParams(window.location.search);
      const shouldScroll = urlParams.get('scroll') === 'true';
      
      const newsLetterElement = document.querySelector('#newsLetterElement');
      
      if (shouldScroll && newsLetterElement) {
        newsLetterElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  }
}

customElements.define("contact-information", ContactInformation);
