import school from '../../assets/images/school.svg';
import account_box from '../../assets/images/account-box.svg';

class HowItWorksComponent extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `
        <div class="how-it-works-component">
            <div class="frame-98">
                <div class="frame-54">
                    <div class="how-it-works circularstd-bold-prussian-blue-36px">
                        How it works
                    </div>
                    <p class="the-easy-donate-star gilroy-medium-oxford-blue-18px">
                        The ccpathways.org started during on Fall 2023
                    </p>
                </div>
                <div class="frame-152">
                    <p class="easy-donateorg-was gilroy-medium-oxford-blue-18px">
                        <span class="gilroy-medium-oxford-blue-18px">
                            The Foreign Non-Profits Fundraising Program is an opportunity for qualifying 
                            non-profits to leverage ccpathways.org's resources to raise funds on their behalf. 
                            Normally, a US-based donor would not be able to make tax-exempt donations directly 
                            to the foreign non-profit. However, as a friend of ccpathways.org, the US-based 
                            donor can make a tax-exempt donation to the foreign non-profit.
                            <br>
                            <br>
                            Note that this program is only open to qualifying non-profits. To qualify, a 
                            foreign non-profit must comply with local government regulations related to 
                            receiving foreign donations. Once you qualify as an ccpathways.org foreign 
                            non-profit partner, you can raise funds through ccpathways.org, without having 
                            to create your own US-based 501c3 institution. As part of the eligibility 
                            process, you will be required to submit proof of your current non-profit 
                            status, and additional information that can help us verify your non-profit 
                            status.
                        </span>
                    </p>
                    <div class="frame-631018">
                        <div class="frame-9">
                            <a href="https://calendly.com/jean-easy-donate-org/easy-donate-org-intro" target="_blank">
                                <div class="btn-4">
                                    <div class="sign-up valign-text-middle circularstd-bold-white-16px">
                                        Get Started
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;
    }
}

class KeyBenefitsComponent extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `
        <div class="key-benefits-component">
            <div class="key-benefits-content">
                <div class="frame-wrapper">
                    <div class="div-wrapper">
                        <div class="key-benefits circularstd-bold-prussian-blue-36px">Key Benefits</div>
                    </div>
                </div>
                <div class="div">
                    <div class="div-2">
                        <div class="benefit-img-wrapper"><img class="img" src="${school}" /></div>
                        <div class="div-3">
                            <div class="benefit-title">Raise funds without creating a US-based 501c3 organization</div>
                            <div class="text-wrapper-2">
                                Creating your own US-based 501c3 organization and staying current as an legal operating entity can be
                                a
                                burden. By partnering with ccpathways.org, you can let us worry about the 501c3 status, while you can
                                focus on
                                your fundraising activities.
                            </div>
                        </div>
                    </div>
                    <div class="div-4">
                        <div class="benefit-img-wrapper"><img class="img" src="${account_box}" /></div>
                        <div class="div-3">
                            <div class="benefit-title">Your donors can make tax-exempt donations</div>
                            <div class="text-wrapper-2">
                                Several US-based donors expect their donations to be tax-exempt. However, US-based donors cannot
                                take a
                                tax-exemption on donations made directly to a foreign non-profit organization. However, as an
                                ccpathways.org
                                partner, your donors will be able to claim a tax deduction on their donations, which greatly
                                increases
                                your odds of raising funds here in the US.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;
    }
}

customElements.define('how-it-works-component', HowItWorksComponent);
customElements.define('key-benefits-component', KeyBenefitsComponent);