import check from '../../assets/images/check.svg';

class ProgramComponent extends HTMLElement {
    connectedCallback() {
        const overview_section = this.getAttribute('overview-section');
        const responsibilities_section = this.getAttribute('responsibilities-section');
        const qualifications_section = this.getAttribute('qualifications-section');
        const training_section = this.getAttribute('training-section');
        const duration_section = this.getAttribute('duration-section');
        const benefits_section = this.getAttribute('benefits-section');

        const sections = [
            overview_section, responsibilities_section, 
            qualifications_section, training_section, 
            duration_section, benefits_section
        ];
        
        var sectionsHtml = `<div class="frame-631015">`;
        sections.forEach((section) => {
            if (section) {
                sectionsHtml += `<div class="frame-6309">`;
                const items = section.split('|');
                sectionsHtml += `
                    <div class="program-detail-title circularstd-bold-prussian-blue-24px">
                        ${items[0]}
                    </div>
                `;
                sectionsHtml += `<div class="frame-631014">`;
                sectionsHtml += `<div class="frame-631012">`;
                if (items.length > 1) {
                    for (let index = 1; index < items.length; index++) {
                        const item = items[index];
                        sectionsHtml += `<div class="frame-6310">`;
                        if (items.length > 2) {
                            sectionsHtml += `<img class="check" src="${check}" alt="check" />`;
                        }
                        sectionsHtml += `
                            <div class="program-detail-item gilroy-medium-oxford-blue-18px">
                                ${item}
                            </div>
                        `;
                        sectionsHtml += `</div>`;
                    }
                }
                sectionsHtml += `</div>`;
                sectionsHtml += `</div>`;
                sectionsHtml += `</div>`;
            }
        });
        sectionsHtml += `</div>`

        this.innerHTML = `
        <div class="program-component">
            <div class="frame-94">
                ${sectionsHtml}
                <div class="aa">
                <div class="frame-48-1">
                    <div class="btn-2 btn-4" id="aprogram-pply-now-button">
                    <div class="sign-up-2 valign-text-middle sign-up-4 circularstd-bold-white-16px">
                        Apply Now
                    </div>
                    </div>
                </div>
                <div class="frame-49-1">
                    <div class="btn-3 btn-4" id="program-schedule-call-button">
                    <div class="sign-up-3 valign-text-middle sign-up-4">
                        Schedule a Call
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        `;
    }
}



customElements.define('program-component', ProgramComponent);



const applyNowElement = document.querySelector('#aprogram-pply-now-button');
const scheduleCallElement = document.querySelector('#program-schedule-call-button');
[applyNowElement, scheduleCallElement].forEach((element) => {
    if (element) {
        element.addEventListener('click', function() {
            window.location.target = '_blank';
            window.location.href = 'https://calendly.com/jean-easy-donate-org/easy-donate-org-intro';
        });
    }
});